import React from 'react';
import PropTypes from 'prop-types';

const site = window.location.hostname.replace('www.', '');

const SearchHeaderContainer = (props) => (
  <form
    className="form-inline"
    onSubmit={(event) => event.preventDefault()}
  >
    <div className="row" style={{ width: '100%', margin: 0 }}>
      <div className="col-sm" style={{ padding: 0 }}>
        <div className="form-group" style={{ marginBottom: 15 }}>
          <label className="sr-only" htmlFor="q">Keyword</label>
          <input
            type="text"
            name="q"
            className="form-control form-control-sm"
            placeholder="Keyword"
            onChange={props.onFilter}
            style={{ width: '100%' }}
            value={props.keyword || ''}
          />
        </div>
      </div>
      <div className="col-sm" style={{ padding: 0 }}>
        <div className="form-group" style={{ marginBottom: 15 }}>
          {
            site === '50books.us'
              ? (
                <>
                  <label className="sr-only" htmlFor="state">State</label>
                  <select
                    name="state"
                    className="form-control form-control-sm"
                    onChange={props.onFilter}
                    value={props.state || ''}
                    style={{ width: '100%' }}
                  >
                    <option value="">State:</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="District of Columbia">District of Columbia</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                </>
              )
              : (
                <>
                  <label className="sr-only" htmlFor="state">State</label>
                  <select
                    name="countryId"
                    className="form-control form-control-sm"
                    onChange={props.onFilter}
                    value={props.countryId || ''}
                    style={{ width: '100%' }}
                  >
                    <option value="">Country:</option>
                    {
                      props.countries.map((country) => (
                        <option key={`country-${country.id}`} value={country.id}>{country.name}</option>
                      ))
                    }
                  </select>
                </>
              )
          }
        </div>
      </div>
      <div className="col-sm" style={{ padding: 0 }}>
        <div className="form-group" style={{ marginBottom: 15 }}>
          <label className="sr-only" htmlFor="ageGroupId">Age group</label>
          <select
            name="ageGroupId"
            className="form-control form-control-sm"
            id="ageGroupId"
            onChange={props.onFilter}
            value={props.ageGroupId || ''}
            style={{ width: '100%' }}
          >
            <option value="">Age group:</option>
            {
              props.ageGroups.map((ageGroup) => (
                <option key={`ageGroup-${ageGroup.id}`} value={ageGroup.id}>{ageGroup.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="col-sm" style={{ padding: 0 }}>
        <div className="form-group" style={{ marginBottom: 15 }}>
          <label className="sr-only" htmlFor="historicalPeriodId">Historical period</label>
          <select
            name="historicalPeriodId"
            className="form-control form-control-sm"
            id="historicalPeriodId"
            onChange={props.onFilter}
            value={props.historicalPeriodId || ''}
            style={{ width: '100%' }}
          >
            <option value="">Historical period:</option>
            {
              props.historicalPeriods.sort((historicalPeriod_a, historicalPeriod_b) => (
                historicalPeriod_a.name.replace(/\D/g, '') > historicalPeriod_b.name.replace(/\D/g, '') ? 1 : -1
              )).map((historicalPeriod) => (
                <option key={`historicalPeriod-${historicalPeriod.id}`} value={historicalPeriod.id}>{historicalPeriod.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="col-sm" style={{ padding: 0 }}>
        <div className="form-group" style={{ marginBottom: 15 }}>
          <label className="sr-only" htmlFor="gender">Main character</label>
          <select
            name="gender"
            className="form-control form-control-sm"
            id="gender"
            onChange={props.onFilter}
            value={props.gender || ''}
            style={{ width: '100%' }}
          >
            <option value="">Main character:</option>
            {
              props.genders.map((gender) => (
                <option key={`gender-${gender}`} value={gender}>{gender}</option>
              ))
            }
          </select>
        </div>
      </div>
    </div>
    <div className="row" style={{ width: '100%', margin: 0 }}>
      <div className="card bg-default" style={{ width: '100%' }}>
        <div className="card-body lead" style={{ marginBottom: 0 }}>
          <div className="form-group float-right">
            <small style={{ fontWeight: 300 }}>Sort by:</small>
            <select
              name="sort"
              className="form-control form-control-sm"
              id="sort"
              onChange={props.onFilter}
              style={{ marginLeft: 10 }}
              value={props.sort || 'title'}
            >
              {
                site === '50books.us' ? (
                  <option value="state">State</option>
                ) : (
                  <option value="country">Country</option>
                )
              }
              <option value="setting_time">Setting time</option>
              <option value="title">Title</option>
              <option value="author">Author</option>
              <option value="random">Random</option>
              <option value="lexile">Lexile measure</option>
              <option value="character_age">Main character age</option>
            </select>
          </div>
          {
            props.updating
              ? (
                <div className="text-center">
                  <img src="/images/loading.svg" alt="Loading" height="20" />
                </div>
              )
              : (
                <>
                  {props.bookCount} {props.bookCount === props.totalBookCount ? null : `of ${props.totalBookCount}`} {props.bookCount === 1 ? 'book' : 'books'}
                  {props.state || props.country || props.historicalPeriodId ?
                    <span> that {props.bookCount === 1 ? 'takes' : 'take'} place in</span>
                    : ''}
                  {props.state ?
                    <span> {props.state}</span>
                    : ''
                  }
                  {props.country ?
                    <span> {props.country.name}</span>
                    : ''
                  }
                  {(props.state || props.country) && props.historicalPeriodId ?
                    <span> in</span>
                    : ''}
                  {props.historicalPeriodId ?
                    <span> the {props.historicalPeriods.find((historicalPeriod) => historicalPeriod.id === props.historicalPeriodId).name.toLowerCase()}</span>
                    : ''
                  }
                  {props.ageGroupId ?
                    <span> for {props.ageGroups.find((ageGroup) => ageGroup.id === props.ageGroupId).name.toLowerCase()} readers</span>
                    : ''
                  }
                  {props.gender ?
                    <span> with {props.gender === 'Animal' ? 'an' : 'a'} {
                      props.gender.toLowerCase()
                    } main character</span>
                    : ''
                  }
                  {props.keyword ?
                    <span> with &quot;{props.keyword}&quot;</span>
                    : ''
                  }
                  {props.tag ?
                    <span> tagged <button className="btn btn-sm btn-secondary" onClick={props.clearTag}>
                      {props.tag} <span className="badge badge-primary">&times;</span>
                    </button></span>
                    : ''
                  }
                </>
              )
          }
        </div>
      </div>
    </div>
  </form>
);

SearchHeaderContainer.propTypes = {
  ageGroupId: PropTypes.number,
  countryId: PropTypes.number,
  bookCount: PropTypes.number.isRequired,
  clearTag: PropTypes.func.isRequired,
  gender: PropTypes.string,
  historicalPeriodId: PropTypes.number,
  keyword: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  sort: PropTypes.string,
  state: PropTypes.string,
  tag: PropTypes.string,
  totalBookCount: PropTypes.number.isRequired,
  updating: PropTypes.bool.isRequired
};

export default SearchHeaderContainer;
