import React from 'react';
import axios from 'axios';

import SearchHeaderContainer from './SearchHeaderContainer';
import SearchResultsContainer from './SearchResultsContainer';

const site = window.location.hostname.replace('www.', '');

class SearchContainer extends React.Component {
  constructor(props) {
    super(props);

    const ageGroupId = localStorage.getItem('ageGroupId');
    const countryId = localStorage.getItem('countryId');
    const country = localStorage.getItem('country');
    const gender = localStorage.getItem('gender');
    const historicalPeriodId = localStorage.getItem('historicalPeriodId');
    const keyword = localStorage.getItem('keyword');
    const sort = localStorage.getItem('sort');
    const state = localStorage.getItem('state');
    const tag = localStorage.getItem('tag');
    const tagId = localStorage.getItem('tagId');

    this.state = {
      ageGroupId: ageGroupId ? parseInt(ageGroupId, 10) : null,
      countryId: countryId ? parseInt(countryId, 10) : null,
      country: country ? parseInt(country, 10) : null,
      books: [],
      gender: gender || null,
      historicalPeriodId: historicalPeriodId ? parseInt(historicalPeriodId, 10) : null,
      keyword: keyword || '',
      loaded: false,
      updating: false,
      sort: sort || 'title',
      state: state || null,
      tag: tag || null,
      tagId: tagId || null,
      totalBookCount: null,
      ageGroups: [],
      countries: [],
      historicalPeriods: []
    };

    this.changeFilter = this.changeFilter.bind(this);
    this.handleClearTag = this.handleClearTag.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.updateSearchResults = this.updateSearchResults.bind(this);
  }

  componentDidMount() {
    this.getOptions('age_group');
    this.getOptions('historical_period');
    this.getOptions('country');
    this.updateSearchResults();
  }

  getOptions(key) {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/options`, {key})
      .then((result) => {
        if (key === 'age_group') {
          this.setState({
            ageGroups: result.data.options
          });
        } else if (key === 'historical_period') {
          this.setState({
            historicalPeriods: result.data.options
          });
        } else if (key === 'country') {
          this.setState({
            countries: result.data.options
          });
        }
      });
  }

  updateSearchResults() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/search`, {
      age_group_id: this.state.ageGroupId,
      domain: process.env.REACT_APP_DOMAIN || site || '50books.us',
      gender: this.state.gender,
      historical_period_id: this.state.historicalPeriodId,
      keyword: this.state.keyword,
      sort: this.state.sort,
      state: this.state.state,
      country_id: this.state.countryId,
      tag_id: this.state.tagId
    })
      .then((result) => {
        this.setState({
          totalBookCount: result.data.book_count,
          books: result.data.books,
          loaded: true,
          updating: false
        });
      });
  }

  changeFilter(changes) {
    const state = this.state;
    state.updating = true;
    changes.forEach((change) => {
      state[change.name] = change.value;
      if (change.value) {
        localStorage.setItem(change.name, change.value);
      } else {
        localStorage.removeItem(change.name);
      }
    });
    this.setState(state, this.updateSearchResults);
  }

  handleClearTag() {
    this.changeFilter([
      { name: 'tagId', value: null },
      { name: 'tag', value: null }
    ]);
  }

  handleFilterChange(e) {
    if (e.target.name === 'q') {
      this.changeFilter([{ name: 'keyword', value: e.target.value }]);
    } else if (e.target.name === 'ageGroupId' || e.target.name === 'historicalPeriodId') {
      this.changeFilter([{ name: e.target.name, value: parseInt(e.target.value, 10) }]);
    } else if (e.target.name === 'countryId') {
      const countryId = parseInt(e.target.value, 10);
      this.changeFilter([
        { name: 'countryId', value: countryId },
        { name: 'country', value: this.state.countries.find((country) => country.id === countryId) }
      ]);
    } else {
      this.changeFilter([{ name: e.target.name, value: e.target.value }]);
    }
  }

  handleTagClick(tag) {
    this.changeFilter([
      { name: 'tagId', value: tag.id },
      { name: 'tag', value: tag.name },
      { name: 'keyword', value: null },
      { name: 'ageGroupId', value: null },
      { name: 'historicalPeriodId', value: null },
      { name: 'state', value: null },
      { name: 'countryId', value: null },
      { name: 'country', value: null },
      { name: 'gender', value: null }
    ]);
  }

  render() {
    return (
      <>
        {this.state.loaded ?
          <span>
            <SearchHeaderContainer
              ageGroupId={this.state.ageGroupId}
              countryId={this.state.countryId}
              country={this.state.country}
              bookCount={this.state.books.length}
              clearTag={this.handleClearTag}
              gender={this.state.gender}
              historicalPeriodId={this.state.historicalPeriodId}
              keyword={this.state.keyword}
              onFilter={this.handleFilterChange}
              sort={this.state.sort}
              state={this.state.state}
              tag={this.state.tag}
              totalBookCount={this.state.totalBookCount}
              updating={this.state.updating}
              ageGroups={this.state.ageGroups}
              countries={this.state.countries}
              historicalPeriods={this.state.historicalPeriods}
              genders={[
                'Male',
                'Female',
                'Animal'
              ]}
            />
            <SearchResultsContainer
              books={this.state.books}
              onTagFilter={this.handleTagClick}
              updating={this.state.updating}
            />
          </span>
          :
          <div className="text-center" style={{ padding: '45px 0' }}>
            <img src="/images/loading.svg" alt="Loading" />
          </div>
        }
      </>
    );
  }
}

export default SearchContainer;
