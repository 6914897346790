import React from 'react';
import PropTypes from 'prop-types';

const site = window.location.hostname.replace('www.', '');

const SearchResultsContainer = (props) => (
  <div>
    {
      props.books.map((book) => (
        <div
          className={`${book.highlighted ? 'card bg-light' : 'card'}`}
          key={`book-${book.id}`}
          style={{ marginBottom: 10 }}
        >
          <div className="row no-gutters">
            <div className="col-md-3" style={{ padding: 20, maxWidth: '100%' }}>
              <img src={`${process.env.REACT_APP_API_ENDPOINT}/books/covers/${book.id}`} className="card-img" alt={book.title} />
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <div className="lead" style={{ marginBottom: 30 }}>
                  <div className="float-right states d-block">
                    {
                      site === '50books.us'
                        ? book.states.map((state) => (
                          <h4
                            className="badge badge-pill badge-secondary"
                            style={{ marginLeft: 5 }}
                            key={`state-${state}`}
                          >
                            {state}
                          </h4>
                        ))
                        : book.countries.map((country) => (
                          <h4
                            className="badge badge-pill badge-secondary"
                            style={{ marginLeft: 5 }}
                            key={`country-${country.id}`}
                          >
                            {country.name}
                          </h4>
                        ))
                    }
                  </div>
                  <h5 className="card-title"><a
                    href={book.amazon_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {book.title}
                  </a></h5>
                  <div className="states d-none">
                    {
                      site === '50books.us'
                        ? book.states.map(state => (
                          <h4
                            className="badge badge-pill badge-secondary"
                            style={{ marginLeft: 5 }}
                            key={`state-${state}`}
                          >
                            {state}
                          </h4>
                        ))
                        : book.countries.map(country => (
                          <h4
                            className="badge badge-pill badge-secondary"
                            style={{ marginLeft: 5 }}
                            key={`country-${country.id}`}
                          >
                            {country.name}
                          </h4>
                        ))
                    }
                  </div>
                </div>
                <div className="row card-text">
                  <div className="col-md-7">
                    By <span dangerouslySetInnerHTML={{
                      __html: book.authors.map((author) => {
                        const label = author.label !== null ? ` (${author.label})` : '';
                        return `<strong>${author.first_name} ${author.last_name}</strong>${label}`;
                      }).join(', ')
                    }}>
                    </span>
                    {book.historical_periods.length > 0 ?
                      <div>Historical periods: {book.historical_periods.map(period => period.name).join(', ')}</div> : ''}
                    {book.awards.length > 0 ?
                      <div>Awards: {book.awards.map(award => award.name).join(', ')}</div> : ''}
                    {book.age_groups.length > 0 ?
                      <div>Age groups: {book.age_groups.map(age_group => age_group.name).join(', ')}</div>
                      : ''
                    }

                    {book.main_character_animal !== null && book.main_character_animal ?
                      <div>Main character: Animal</div> : ''}
                    {book.main_character_gender !== null && book.main_character_age !== null ?
                      <div>
                        Main character: {book.main_character_age}-year-old {book.main_character_gender.toLowerCase()}
                      </div> : ''}
                    {book.main_character_gender === null && book.main_character_age !== null ?
                      <div>Main character: {book.main_character_age}-year-old</div> : ''}
                    {book.main_character_gender !== null && book.main_character_age === null ?
                      <div>Main character: {book.main_character_gender}</div> : ''}
                  </div>
                  <div className="col-md-5">
                    {book.copyright !== null ? (
                      <div>
                        Copyright: {book.copyright}
                      </div>
                    ) : ''}
                    {book.pages !== null ? (
                      <div>
                        Pages: {book.pages}
                      </div>
                    ) : ''}
                    {book.lexile_measure !== null ? (
                      <div>
                        Lexile measure: {book.lexile_measure}
                      </div>
                    ) : ''}
                    {book.setting_time !== null ? (
                      <div>
                        Setting time: {book.setting_time}
                      </div>
                    ) : ''}
                  </div>
                </div>
                {book.blurb !== null ? (
                  <div
                    style={{ marginTop: 5, marginBottom: 5 }}
                    id={`book_blurb-${book.id}`}
                  >
                    {book.blurb}
                  </div>
                ) : ''}
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                  {book.tags.map((tag) => (
                    <span key={tag.id} className="lead" style={{ marginBottom: 5 }}>
                      <button
                        className="btn btn-outline-info btn-sm"
                        style={{ marginRight: 5, display: 'inline-block' }}
                        onClick={() => props.onTagFilter(tag)}
                      >
                        {tag.name}
                      </button>
                    </span>
                  ))}
                </div>
                {book.movies.length > 0 ?
                  <div>
                    <strong>Related movies:</strong><br />
                    {book.movies.map(movie => (
                      <div key={movie.id}>
                        <a href={movie.amazon_link} target="_blank" rel="noopener noreferrer" >{movie.title}</a>, {movie.rating}, {movie.year}
                        {movie.blurb !== null ?
                          <p>{movie.blurb}</p>
                          : ''}
                      </div>
                    ))}
                  </div>
                  : ''}
              </div>
            </div>
          </div>
        </div>
      ))
    }
  </div>
);

SearchResultsContainer.propTypes = {
  books: PropTypes.array.isRequired,
  onTagFilter: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired
};

export default SearchResultsContainer;
