import React from 'react';

import SearchContainer from './containers/SearchContainer';

import 'bootstrap/dist/css/bootstrap.min.css';
// import './50books.us.css';

const site = window.location.hostname.replace('www.', '');

const link = document.createElement('link');
link.setAttribute('rel', 'stylesheet');
link.setAttribute('type', 'text/css');
link.setAttribute('href', `/css/${site}.css`);
document.getElementsByTagName('head')[0].appendChild(link);

if (site === '50books.us') {
  document.title = '50 Books | 50 States';
} else {
  document.title = '50 Books of the World';
}


const App = () => (
  <div className="container">
    <header className="text-center">
      <img src={`/images/${site}.png`} alt="50 Books" className="img-fluid" />
    </header>

    <main role="main">
      <SearchContainer />
    </main>

    <footer className="footer text-center">
      <p className="lead">&copy; {new Date().getFullYear()} {site}</p>
      <p className="text-muted" style={{ lineHeight: 1 }}>
        We participate in the Amazon Services LLC Associates Program, an affiliate advertising program designed
        to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.
      </p>
    </footer>
  </div>
);

export default App;
